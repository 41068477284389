/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Link from '../components/Link';

function CookiePolicy({ location }) {
  const title = 'Üyelik Aydınlatma Metni - Tıkla Gelsin';
  const metaData = 'Tıkla Gelsin üyelik aydınlatma metnine bu sayfadan ulaşabilirsiniz.';
  const ref = useRef(null);
  return (
    <Layout sidebar={false} location={location} hasBreadCrumb layoutRef={ref}>
      <SEO title={title} description={metaData} />
      <div className="kvkk-page  cookie-agreement">
        <p style={{ textAlign: 'center' }}>
          <strong>ÇEREZLERLE İŞLENEN KİŞİSEL VERİLERE YÖNELİK</strong>
        </p>
        <p style={{ textAlign: 'center' }}>
          <strong>TIKLA GELSİN AYDINLATMA METNİ</strong>
        </p>
        <p>
          Biz,{' '}
          <strong>
            {' '}
            Ata Express Elektronik İletişim Tanıtım Pazarlama Dağıtım Sanayi ve Ticaret A.Ş. (“Ata Express”){' '}
          </strong>{' '}
          olarak kişisel verilerinizin korunmasına değer veriyor, dolayısıyla sizin de aynı hassasiyeti göstermenizi
          bekliyoruz. Bu sebeple hangi kişisel verilerinizi ne amaçla kullandığımızı detaylarıyla anlatan bu metni
          okumanız, bunların idari ve teknik güvenliğinden “<strong>Veri Sorumlusu”</strong> sıfatıyla sorumlu olan{' '}
          <strong>Ata Express</strong>’in yükümlülüklerini net bir şekilde anladığınızı göstermeniz bizim için çok
          önemlidir.
        </p>
        <p>
          <strong>Neden bu metni okuyorum?</strong>
        </p>
        <p>
          <strong>7 Nisan 2016</strong> tarihinde yürürlüğe giren{' '}
          <strong>6698 sayılı Kişisel Verilerin Korunması Kanununun</strong> (“
          <strong>KVKK</strong>”) <strong>10.</strong> <strong>maddesi</strong>, kişisel verileri işlenen (kullanılan)
          kişilerin (<strong>KVKK’da ilgili kişi olarak ifade edilmektedir</strong>) bu verileri kullanan “
          <strong>Veri Sorumlusu</strong>” tarafından bilgilendirilmesini zorunlu kılmaktadır. Bizler de{' '}
          <strong>Veri Sorumlusu</strong> olarak{' '}
          <u>sizler hakkında işleyeceğimiz kişisel verilerle ilgili bilgilendirildiğinizi ispat etmek durumundayız.</u>
        </p>
        <p>
          <strong>Kullanılan kişisel verileriniz neler olup hangi yollarla elde edilmektedir?</strong>
        </p>
        <p>
          <strong>Ata Express</strong> olarak aydınlatma metni kapsamındaki kişisel verilerinizi,{' '}
          <a href="https://www.tiklagelsin.com/">www.tiklagelsin.com</a> internet sitemizi ziyaretiniz ile birlikte
          dijital yollarla (<strong>KVKK’da “otomatik olan” yöntem şeklinde ifade edilmektedir.</strong>) elde
          etmekteyiz. Elde edilen kişisel verilerinizin neler olduğu ve elde etmemizin hukuki sebebi aşağıda
          açıklanmıştır:
        </p>
        <table
          border="1"
          cellPadding="8"
          style={{ maxWidth: 900, overflow: 'auto', display: 'flex', border: 'none', borderColor: 'gray' }}
        >
          <tbody>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Veri Kategorisi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>İşlenen Kişisel Verileriniz</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Elde Etme Hukuki Sebebi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>İşlem Güvenliği Verisi</strong>
                </p>
              </td>
              <td>
                <p>IP bilginiz</p>
              </td>
              <td>
                Belirtilen bilginiz, <strong>Tıkla Gelsin</strong> internet sitemizi ziyaretiniz vasıtasıyla otomatik
                yollarla ve{' '}
                <u>
                  temel hak ve özgürlüklerinize zarar vermemek kaydıyla <strong>Ata Express</strong>’in meşru olan
                  menfaatlerinin yerine getirilmesi için işlemenin zorunlu olması
                </u>{' '}
                hukuki sebebiyle elde edilmektedir.
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Pazarlama Verisi</strong>
                </p>
              </td>
              <td>
                Çerezler vasıtasıyla elde edilen ziyaretlerinize ilişkin bilgiler (Ziyaretçilerin gerçek kişi olduğuna
                dair bilgi, ziyaret sıklığınız, süresi ve ziyaret sayınız, ziyaret ettiğiniz sayfalar)
              </td>
              <td>
                <strong>Tıkla Gelsin</strong> internet sitemizi ziyaretiniz vasıtasıyla otomatik yollarla ve{' '}
                <u>açık rızanızın varlığı</u> halinde elde edilmektedir.
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <strong>Elde edilen kişisel verileriniz hangi amaçla ve hukuki sebeple kullanılmaktadır?</strong>
        </p>
        <p>
          <strong>Ata Express</strong> tarafından aşağıda yer verilen tabloda, aydınlatma yükümlülüğünün yerine
          getirildiği faaliyet kapsamında gerçekleştirilen kişisel veri işleme amaçları detaylandırılmıştır. Her bir
          kişisel veri işleme amacı, amaç kapsamında kullanılan kişisel veri kategorileri ve ilgili hukuki sebep ile
          eşleştirilmiştir.
        </p>
        <table
          border="1"
          cellPadding="8"
          style={{ maxWidth: 900, overflow: 'auto', display: 'flex', border: 'none', borderColor: 'gray' }}
        >
          <tbody>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Kişisel Veri İşleme Amacı</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>İlgili Kişisel Veri Kategorisi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>İşleme Hukuki Sebebi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <ul style={{ paddingLeft: 14 }}>
                  <li>İnternet sitesi güvenliğimizin sağlanması,</li>
                  <li>Çerezlere ilişkin tercihlerinizin kaydedilmesi amaçlarıyla</li>
                </ul>
              </td>
              <td>İşlem güvenliği kategorisindeki verileriniz,</td>
              <td>
                <u>Hukuki yükümlülüğümüzün yerine getirilmesi için işlemenin zorunlu olması</u> hukuki sebebiyle
                işlenmektedir.
              </td>
            </tr>
            <tr>
              <td>
                <ul style={{ paddingLeft: 14 }}>
                  <li>
                    <strong>Tıkla Gelsin</strong> internet sitesi ziyaretçilerinin kullanımlarına yönelik sunucuları
                    yurt dışında bulunan iş ortaklarımızca analitik ölçümler yapılması,
                  </li>
                  <li>
                    Analitik ölçümler vasıtasıyla öğrenilen ziyaretçi hareketlerine yönelik hizmetlerimizin
                    geliştirilmesi,
                  </li>
                  <li>
                    <strong>Tıkla Gelsin</strong> internet sitesinde gerçekleştirilen ziyaretlerin sayısının
                    artırılabilmesi,
                  </li>
                  <li>
                    Potansiyel müşterilerin <strong>Tıkla Gelsin</strong> internet sitesine yönlendirilmesi,
                  </li>
                  <li>Reklamcılık faaliyetlerinin yürütülebilmesi amaçlarıyla,</li>
                </ul>
              </td>
              <td>Pazarlama kategorisindeki verileriniz,</td>
              <td>
                <u>Açık rızanızın varlığı halinde işlenmektedir.</u>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          Çerezlerin kullanımına ilişkin tercihlerinizi dilediğiniz zaman{' '}
          <a
            href="javascript:void(0)"
            onClick={() => {
              ref.current.showCookieModal();
            }}
          >
            buradan
          </a>{' '}
          değiştirebilirsiniz.
        </p>
        <p>
          <strong>Kullanılan Çerezler Ve Kullanım Amaçları</strong>
        </p>

        <p>
          <strong> - Gerekli Çerezler</strong>
        </p>
        <table
          border="1"
          cellPadding="8"
          style={{ maxWidth: 900, overflow: 'auto', display: 'flex', border: 'none', borderColor: 'gray' }}
        >
          <tbody>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez İsmi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez Tanımı</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Şirket</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Tipi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Saklama Süresi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>firebase-installations-database#firebase-installations-store</td>
              <td>Web sitesinin İçerik Dağıtım Ağı (CDN) ile bağlantılı olarak kullanılan kontrol çerezi</td>
              <td>tiklagelsin.com</td>
              <td>IndexedDB</td>
              <td>Kalıcı</td>
            </tr>
            <tr>
              <td>test_cookie</td>
              <td>Kullanıcının tarayıcısının çerezleri destekleyip desteklemediğini kontrol etmek için kullanılır</td>
              <td>doubleclick.net</td>
              <td>HTTP</td>
              <td>1 Gün</td>
            </tr>
            <tr>
              <td>ts</td>
              <td>Bu çerez, web sitesinde PayPal oturum açma işlevi için gereklidir.</td>
              <td>creativecdn.com</td>
              <td>HTTP</td>
              <td>1 Yıl</td>
            </tr>
            <tr>
              <td>u</td>
              <td>Web sitesinde kayıt işlevi için gerekli</td>
              <td>creativecdn.com</td>
              <td>HTTP</td>
              <td>1 Yıl</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <strong> - Tercih Çerezler</strong>
        </p>
        <table
          border="1"
          cellPadding="8"
          style={{ maxWidth: 900, overflow: 'auto', display: 'flex', border: 'none', borderColor: 'gray' }}
        >
          <tbody>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez İsmi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez Tanımı</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Şirket</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Tipi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Saklama Süresi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>(@@scroll#)</td>
              <td>Sınıflandırılmamış</td>
              <td>tiklagelsin.com</td>
              <td>HTML</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>firebaseLocalStorageDb #firebaseLocalStorage</td>
              <td>
                Sohbet kutusundaki bildirim işlevini kolaylaştırarak, sohbet kutusunda bir yanıt verildiğinde web
                sitesinin destek ekibinin kullanıcıyı bilgilendirmesine olanak tanır
              </td>
              <td>tiklagelsin.com</td>
              <td>IndexedDB</td>
              <td>Kalıcı</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <strong> - İstatistik Çerezler</strong>
        </p>
        <table
          border="1"
          cellPadding="8"
          style={{ maxWidth: 900, overflow: 'auto', display: 'flex', border: 'none', borderColor: 'gray' }}
        >
          <tbody>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez İsmi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez Tanımı</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Şirket</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Tipi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Saklama Süresi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>_dc_gtm_UA-#</td>
              <td>
                Bir Google Analytics komut dosyası etiketinin yüklenmesini kontrol etmek için Google Tag Manager
                tarafından kullanılır
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>1 Gün</td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>
                Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel veriler oluşturmak için kullanılan
                benzersiz bir kimlik kaydeder.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>2 Yıl</td>
            </tr>
            <tr>
              <td>_ga_#</td>
              <td>
                Google Analytics tarafından, bir kullanıcının web sitesini kaç kez ziyaret ettiği ile ilk ve en son
                ziyaret tarihlerine ilişkin verileri toplamak için kullanılır.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>2 Yıl</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>
                Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel veriler oluşturmak için kullanılan
                benzersiz bir kimlik kaydeder.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>1 Gün</td>
            </tr>
            <tr>
              <td>_tt_enable_cookie</td>
              <td>Gömülü hizmetlerin kullanımını izlemek için sosyal ağ hizmeti TikTok tarafından kullanılır</td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>1 Yıl</td>
            </tr>
            <tr>
              <td>collect</td>
              <td>
                Ziyaretçinin cihazı ve davranışı hakkında Google Analyticse veri göndermek için kullanılır. Ziyaretçiyi
                cihazlar ve pazarlama kanalları arasında takip eder.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>FPID</td>
              <td>
                Kullanıcıların web sitesindeki davranışlarına ilişkin istatistiksel verileri kaydeder. Web sitesi
                operatörü tarafından dahili analiz için kullanılır
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>FPLC</td>
              <td>
                Ziyaretçinin web sitesini nasıl kullandığına ilişkin istatistiksel veriler oluşturmak için kullanılan
                benzersiz bir kimlik kaydeder.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>Oturum süresi boyunca</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <strong> - Pazarlama Çerezler</strong>
        </p>
        <table
          border="1"
          cellPadding="8"
          style={{ maxWidth: 900, overflow: 'auto', display: 'flex', border: 'none', borderColor: 'gray' }}
        >
          <tbody>
            <tr>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez İsmi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Çerez Tanımı</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Şirket</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Tipi</strong>
                </p>
              </td>
              <td>
                <p style={{ textAlign: 'center' }}>
                  <strong>Saklama Süresi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>__rtbhouse.lid</td>
              <td>
                Birden fazla web sitesindeki kullanıcı davranışı hakkında bilgi toplar. Bu bilgiler web sitesindeki
                reklamların alaka düzeyini optimize etmek için kullanılır.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTML</td>
              <td>Kalıcı</td>
            </tr>
            <tr>
              <td>_fbp</td>
              <td>
                Facebook tarafından, üçüncü taraf reklamverenlerden gerçek zamanlı teklif verme gibi bir dizi reklam
                ürününü sunmak için kullanılır.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>3 Ay</td>
            </tr>
            <tr>
              <td>_gcl_au</td>
              <td>
                Google AdSense tarafından, hizmetlerini kullanan web sitelerinde reklam verimliliğini denemek için
                kullanılır.
              </td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>3 Ay</td>
            </tr>
            <tr>
              <td>_ttp</td>
              <td>Gömülü hizmetlerin kullanımını izlemek için sosyal ağ hizmeti TikTok tarafından kullanılır.</td>
              <td>tiklagelsin.com</td>
              <td>HTTP</td>
              <td>1 Yıl</td>
            </tr>
            <tr>
              <td>_ttp</td>
              <td>Gömülü hizmetlerin kullanımını izlemek için sosyal ağ hizmeti TikTok tarafından kullanılır.</td>
              <td>tiktok.com</td>
              <td>HTTP</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>ads/ga-audiences</td>
              <td>
                Kullanıcının imleç hareketleri ile sayfadan ayrılmak isteyip istemediğini tespit etmek için kullanılır.
                Bu, web sitesinin, kullanıcıyı web sitesinde tutmak veya bunları müşteriye dönüştürmek için belirli
                açılır pencereleri tetiklemesine olanak tanır.
              </td>
              <td>google.com</td>
              <td>Pixel</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>ads/ga-audiences</td>
              <td>
                Kullanıcının imleç hareketleri ile sayfadan ayrılmak isteyip istemediğini tespit etmek için kullanılır.
                Bu, web sitesinin, kullanıcıyı web sitesinde tutmak veya bunları müşteriye dönüştürmek için belirli
                açılır pencereleri tetiklemesine olanak tanır.
              </td>
              <td>google.ie</td>
              <td>Pixel</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>anj</td>
              <td>
                Geri dönen kullanıcının cihazını tanımlayan benzersiz bir kimlik kaydeder. Kimlik, hedeflenen reklamlar
                için kullanılır.
              </td>
              <td>adnxs.com</td>
              <td>HTTP</td>
              <td>3 Ay</td>
            </tr>
            <tr>
              <td>pagead/1p-user-list/#/</td>
              <td>Sınıflandırılmamış</td>
              <td>google.com</td>
              <td>Pixel</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>pagead/1p-user-list/#/</td>
              <td>pagead/1p-user-list/#/</td>
              <td>google.ie</td>
              <td>Pixel</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>tt_appInfo</td>
              <td>Gömülü hizmetlerin kullanımını izlemek için sosyal ağ hizmeti TikTok tarafından kullanılır.</td>
              <td>analytics.tiktok.com</td>
              <td>HTML</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>tt_pixel_session_index</td>
              <td>Gömülü hizmetlerin kullanımını izlemek için sosyal ağ hizmeti TikTok tarafından kullanılır.</td>
              <td>analytics.tiktok.com</td>
              <td>HTML</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>tt_sessionId</td>
              <td>Gömülü hizmetlerin kullanımını izlemek için sosyal ağ hizmeti TikTok tarafından kullanılır.</td>
              <td>analytics.tiktok.com</td>
              <td>HTML</td>
              <td>Oturum süresi boyunca</td>
            </tr>
            <tr>
              <td>uuid2</td>
              <td>
                Geri dönen kullanıcının cihazını tanımlayan benzersiz bir kimlik kaydeder. Kimlik, hedeflenen reklamlar
                için kullanılır.
              </td>
              <td>adnxs.com</td>
              <td>HTTP</td>
              <td>3 Ay</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <strong>Kişisel verileriniz başkalarına aktarılıyor mu?</strong>
        </p>
        <p>
          Çerezler ile yürütülen faaliyetlerimiz kapsamında açık rızanızı sunmanız halinde kişisel verileriniz;{' '}
          <strong>Ata Express</strong> hizmetlerinin size özelleştirilerek geliştirilmesi, pazarlama faaliyetlerinin
          yürütülebilmesi amaçları ile geliştiriciler ve sunucuları yurt dışında bulunan hizmet sunucularına{' '}
          <strong>aktarılabilmektedir.</strong>
        </p>
        <p>
          <strong>KVKK size hangi hakları veriyor?</strong>
        </p>
        <p>
          <strong>KVKK</strong> size kişisel verileriniz üzerinde kontrol sağlayabilmeniz amacıyla, (a) kişisel
          verilerinizin işlenip işlenmediğini öğrenme; (b) işlenmişse buna ilişkin bilgi talep etme, (c) işlenme amacını
          ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme; (d) varsa yurt içinde veya yurt dışında kişisel
          verilerinizin aktarıldığı üçüncü kişileri bilme; (e) kişisel verilerinizin eksik veya yanlış işlenmiş olması
          hâlinde bunların düzeltilmesini isteme; (f) kişisel verilerinizin silinmesini veya yok edilmesini isteme gibi
          ve bunlarla sınırlı olmamak üzere bir çok hak sağlamaktadır. Bu hakların tamamını ve detaylarını{' '}
          <strong>KVKK</strong>’nın 11. maddesinde bulabilirsiniz.
        </p>
        <p>
          <strong>Haklarınızı nasıl kullanabilirsiniz?</strong>
        </p>
        <p>
          Belirtilen haklarınızı kullanmak isterseniz taleplerinizi bize yazılı olarak (örneğin{' '}
          <strong>ihtarname veya iadeli taahhütlü mektup vasıtasıyla</strong>) veya kayıtlı elektronik posta (KEP)
          adresi, güvenli elektronik imza, mobil imza ya da bize daha önce bildirdiğiniz ve{' '}
          <strong>sistemimizde</strong> kayıtlı bulunan elektronik posta adresinizi kullanmak suretiyle{' '}
          <a href="mailto:kvkk@tabgida.com.tr">kvkk@tabgida.com.tr</a> adresine iletebilirsiniz.
        </p>
        <Link to="/" className="go-back">
          Ana Sayfaya Dön
        </Link>
      </div>
    </Layout>
  );
}

CookiePolicy.propTypes = {
  location: PropTypes.object,
};

export default CookiePolicy;
